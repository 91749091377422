@media screen and (min-width: 1400px) {
    .custom-list-benefit li{
        line-height: 1.75 !important;
        font-size: 18px;
    }
    .benefit-img {
        height: 440px !important;
        margin-left: 20px;
    }
    .benefit-text {
        margin-top: 20px;
    }
    .benefit-text h4 {
        margin-left: -22.5px !important;
    }
}

@media screen and (min-width: 992px) {
    .benefit-img {
        width: 390px;
        height: 375px;
    }
    .benefit-row {
        background-image: url("https://d2b7dijo04ypct.cloudfront.net/SPL-Force/who-will-benefit-bg.webp");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 35px 12px;
    }
    .benefit-row h4 {
        width: max-content;
        background-color: #650967;
        border-radius: 0 25px 25px 0;
        padding: 10px 50px 10px 10px;
        margin-left: -42px;
        margin-top: 12px;
    }
    .cutom-list-benefit {
        font-size: 18px;
    }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .benefit-img {
        width: 390px;
        height: 440px;
    }
    .benefit-row h4 {
        margin-left: 0;
        margin-top: 70px;
    }
    .benefit-row {
        padding-top: 0;
        padding-bottom: 0;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
    .benefit-img {
        width: 370px;
    }
    .benefit-row h4 {
        margin-left: -2px;
        margin-top: 18px;
    }
    .benefit-img {
        margin-top: 10px;
    }
}

@media screen  and (min-width: 320px) and (max-width: 374.9px) {
    .benefit-row {
        width: 310px;
    }
    .benefit-text {
        margin-top: 0px;
    }
    .benefit-img {
        margin-top: 80px;
        width: 200px !important;
    }
    .custom-list-benefit li{
        line-height: 1.75 !important;
        font-size: 16px;
    }
    .benefit-text h4 {
        margin-top: 50px !important;
    }
}

@media screen  and (min-width: 375px) and (max-width: 500.9px) {
    .benefit-img {
        width: 240px !important;
        margin-top: 50px !important;
    }
    .benefit-text {
        margin-top: 52px;
    }
    .custom-list-benefit li{
        line-height: 1.75 !important;
    }
}

@media screen  and (min-width: 501px) and (max-width: 600.9px) {
    .benefit-row {
        width: 450px;
    }
    .benefit-img {
        margin-top: 50px !important;
        width: 300px !important;
    }
    .benefit-text {
        margin-top: 50px;
    }
}

@media screen  and (min-width: 601px) and (max-width: 991.9px) {
    .custom-list-benefit li{
        line-height: 1.75 !important;
        font-size: 18px;
    }
    .benefit-row {
        width: 480px;
    }
    .benefit-img {
        margin-top: 50px;
    }
    .benefit-text {
        margin-top: 40px;
    }
}

@media screen and (max-width: 991.9px) {
    .cutom-list-benefit ul{
        font-size: 18px;
    }
    .benefit-img {
        width: 300px;
    }
    .benefit-row {
        background-image: url("https://d2b7dijo04ypct.cloudfront.net/SPL-Force/benefit-img-mobile.webp");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin: auto;
    }
    .benefit-text h4 {
        text-align: center;
        background-color: #650967;
        padding: 10px 25px;
        border-radius: 25px;
        width: max-content;
        margin: auto auto 20px auto;
    }
}