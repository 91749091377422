@font-face {
    font-family: "Praise";
    font-style: normal;
    font-weight: 400;
    src: "./Praise-Regular.ttf";
}
.bannercontainer {
  padding-top: 6rem;
}

.banner-paragraph {
  text-align: justify;
  hyphens: auto;
}

.orange-box {
  background-color: #FF6102;
  height: 16px;
  width: 30px;
  margin: 5px 10px auto auto;
}

.banner-first-section {
  font-size: 26px;
  font-weight: 500;
}

.banner-second-section h1 {
  background: linear-gradient(91deg, #ff3471 4.24%, #ff5400 67.55%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Praise";
}

.banner-third-box p{
    font-size: 18px;
}

.sam-img {
    width: 250px;
    margin-bottom: -4px;
}

.banner-fourth-section{
    text-align: center;
}

.banner-fourth-section h4{
    background-image: url("https://d2b7dijo04ypct.cloudfront.net/SPL-Force/sam-name-bg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    padding: 10px;
    text-align: center;
    position: relative;
    z-index: 2;
    margin-top: -16px;
}

@media screen and (min-width: 1600px) {
  .banner-first-section h4 {
    margin-top: -15px;
  }
  .time-venue {
    margin-top: -3.6rem !important;
  }
}

@media screen and (min-width: 320px) and (max-width: 374.9px) {
  .banner-first-section p {
    font-size: 18px;
    margin-bottom: 0px !important;
  }
  .banner-second-section h2 {
    font-size: 17px;
  }
  .certification {
    margin-top: -65px;
    width: 310px;
    height: 110px;
  }
}

@media screen and (min-width: 375px) and (max-width: 400.9px) {
  .banner-first-section p {
    font-size: 22px;
    margin-bottom: 0px !important;
  }
  .banner-second-section h2 {
    font-size: 20px;
  }
  .certification {
    margin-top: -75px;
    width: 355px;
    height: 125px;
  }
}

@media screen and (min-width: 401px) and (max-width: 767.9px) {
  .banner-first-section p {
    font-size: 22px;
    margin-bottom: 0px !important;
  }
  .banner-second-section h2 {
    font-size: 22px;
  }
  .certification {
    margin-top: -83px;
    width: 390px;
    height: 140px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991.9px) {
  .banner-first-section p {
    font-size: 22px;
    margin-bottom: 0px !important;
  }
  .banner-second-section h2 {
    font-size: 25px;
  }
  .certification {
    margin-top: -95px;
    width: 500px;
    height: 160px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-fourth-section h4 {
    background-color: #594B45 !important;
    background-image: none;
    width: max-content;
    margin-left: auto;
    margin-right: auto;
    padding: 10px 25px;
    border-radius: 15px;
  }
}

@media screen and (max-width: 991.9px) {
  .banner {
    background-image: url("https://d2b7dijo04ypct.cloudfront.net/SPL-Force/banner-bg-mobile.webp");
    background-repeat: no-repeat;
  }
  .banner-paragraph {
    margin-top: 30px;
    font-size: 18px;
  }
}

@media screen and (min-width: 992px) {
  .form-container {
    height: 450px;
    margin-bottom: -4rem;
    position: relative;
    z-index: 2;
  }
  
  .banner {
    background-image: url("https://d2b7dijo04ypct.cloudfront.net/SPL-Force/banner-bg-desktop.webp");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .banner-second-section h1 {
    font-size: 100px;
  }
  .banner-second-section h2 {
    font-size: 2.3rem;
  }
  .certification {
    width: 700px;
    margin-top: -125px;
  }
}
