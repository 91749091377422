.opportunity, .disclaimer-footer {
    color: #898684;
}
.register-btn {
    background-color: #FF5400;
    border-radius: 6.83px;
    border: none;
    font-size: 22px;
}
.register-btn:hover {
    background-color: #FFFFFF;
    color: #FF5400 !important;
}
.register-btn a {
    color: #FFFFFF;
    text-decoration: none;
}
.register-btn a:hover {
    color: #FF5400;
    font-weight: 600 !important;
}
.footer {
    padding-bottom: 0px;
}

@media screen and (min-width: 992px) {
    .opportunity {
        font-size: 18px;
    }
    .disclaimer-footer {
        font-size: 15px;
    }
    .register-btn {
        font-size: 25px;
    }
}

@media screen and (max-width: 991.9px) {
    .register-btn {
        font-size: 20px;
        padding: 10px 25px;
    }
    .opportunity {
        font-size: 18px;
    }
}