.time-venue {
  font-size: 18px;
  align-items: center;
}
.time-venue p {
  font-size: 18px;
}
.custom-list-time {
  list-style: none;
  padding-left: 0;
}
.purple {
  text-align: justify;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-list-time li {
  margin-bottom: 10px;
  /* color: #2E3039; */
  font-size: 18px;
  font-weight: 600;
}

.cross-text {
    position: relative;
}

.cross-text::before, .cross-text::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #000;
    transform: translateY(-50%) rotate(20deg);
}

.cross-text::after {
    transform: translateY(-50%) rotate(-20deg);
}

.custom-list-time li::before {
  content: "";
  display: inline-block;
  height: 20px;
  width: 20px;
  background-image: url("https://d2b7dijo04ypct.cloudfront.net/SPL-Force/tick.png");
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: -3px;
}
@media screen and (min-width: 2000px) {
  .time-venue {
      background-size: auto !important;
      margin-top: -1rem !important;
  }
  .purple {
    margin-left: -20px;
  }
}
@media screen and (min-width: 992px) {
  .time-venue {
    /* background-image: url("https://d2b7dijo04ypct.cloudfront.net/SPL-Force/date-venue-bg.webp"); */
    background-color: #650967;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    padding: 30px;
    margin-top: -1rem;
  }
  .time-col {
    margin-left: 25px;
  }
  .time {
    margin-left: -35px;
  }
}

@media screen and (max-width: 991.9px) {
    .purple {
        background-color: #650967;
        padding: 15px;
    }
    .time-col {
        background-color: #001049;
        padding: 15px;
    }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .custom-list-time li{
        margin-left: -15px;
        font-size: 16px  !important;
    }
    .time-venue {
        background-size: 100% 100%;
        margin-top: 1rem !important;
        font-size: 16px;
        padding: 30px;
    }
    .clock-cal {
        justify-content: space-between;
    }
}

@media screen and (min-width: 1200px)  and (max-width: 1399px) {
    .time-venue {
        background-size: 100% 100%;
        padding: 40px;
        margin-top: -1rem !important;
    }
    .time-col {
        font-size: 16px;
    }
}

@media screen and (min-width: 1600px) and (max-width: 1999px) {
  .time-venue {
    margin-top: -1rem !important;
  }
}

@media screen and (max-width: 992px) {
  .time-venue {
    margin-top: -0.5rem !important;
  }
}
