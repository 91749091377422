.learn-container h4 {
  width: max-content;
  margin: auto;
  background-color: #650967;
  padding: 10px 25px;
  margin: -25px auto auto auto;
}

.learn-row {
  padding: 30px 20px;
}

.custom-list-learn {
  list-style: none;
  padding-left: 0;
}

.custom-list-learn li {
  margin-bottom: 10px;
  /* color: #2E3039; */
  font-size: 17px;
  display: flex;
}

.custom-list-learn li::before {
  content: "";
  display: inline-block;
  width: 15px !important;
  height: 15px !important;
  min-width: 15px;
  min-height: 15px;
  border-radius: 0 20% 10% 20%;
  background-color: #2e3039;
  margin-right: 10px;
  margin-top: 5px;
}

.learn-container {
  margin-top: 7rem;
  margin-bottom: 4rem;
}

@media screen and (max-width: 991.9px) {
    .custom-list-learn {
        margin-top: 1.5rem;
    }
}

@media screen and (max-width: 500.9px) {
  .learn-container {
    border-top: 2px dotted #ffffff;
    border-bottom: 2px dotted #ffffff;
  }
}

@media screen and (min-width: 501px) {
  .learn-container {
    border: 2px dotted #ffffff;
    border-radius: 25px;
  }
}
