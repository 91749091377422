/**
 * @license
 * Copyright© Celligence International, LLC - 2023
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
*/
*{
    font-family: "Poppins";
}
@font-face {
    font-family: 'handycheera';
    font-weight: bold;
    src: url('./components/fonts/Handycheera_Regular.otf');
    font-display: swap;
  }
  @font-face {
    font-family: 'Flood';
    font-weight: bold;
    src: url('./components/fonts/Flood.otf');
    
  }
  @font-face {
    font-family: 'CenturyGothic';
    src: local('CenturyGothic'), url('./components/fonts/Century-Gothic.ttf') format('truetype');
    font-display: swap;
}
@font-face {
  font-family: 'Praise';
  src: local('Praise'), url('./components/fonts/Praise-Regular.ttf') format('truetype');
  font-display: swap;
}
@font-face {
    font-family: 'CenturyGothicBold';
    src: local('CenturyGothicBold'), url('./components/fonts/Century-Gothic.ttf') format('truetype');
    font-display: swap;
}
.mainPage {
  background-color: #010722;
  color: #FFFFFF;
}
 @media screen and (min-width: 992px) {
   .mobile {
     display: none;
   }
 }

 @media screen and (max-width: 991.9px) {
  .desktop {
    display: none;
  }
}